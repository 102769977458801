// @ts-check

import {
  canAccessClaimSearch,
  canAccessClaimReport,
  canAccessTaskReport,
  canAccessPaymentReport,
  canAssessEmailTaskDetailsReport,
  canAccessFinancialReports,
  canAccessAuditReports,
  canAccessConfigurationAdmin,
  canAccessUsersAdmin,
  canAccessRolesAdmin,
  canAccessOrgAdmin,
  canAccessApprovalLimitsAdmin,
  canAccessWorkflowAdmin,
  canAccessTriageAdmin,
  canAccessEmailTemplatesAdmin,
  canAccessAlertsAdmin,
  canAccessTaskRagAdmin,
  canAccessSubclaimAdmin,
  canAccessOptionsAdmin,
  canAccessClassOfBusinessAdmin,
  canAccessClassOfBusinessGroupAdmin,
  canAccessHolidayAdmin,
  canAccessPaymentsAdmin,
  canAccessParticipantsAdmin,
  canAccessMasterDataAdmin,
  canAccessEventManagementAdmin,
} from '@/services/ability'
import { RouteItems } from '@/router/routes'
import { RouteNames } from '@/router/routes'
import { useApplicationStore } from '@/stores/ApplicationStore'
import { useConfigurationStore } from '@/stores/configuration/ConfigurationStore'
import { useAccessLogService } from '@/services/api/useAccessLogService'
import { useNotyf } from '@/services/useNotyf'
import { setLocaleMessages, i18n } from '@/plugins/useVueI18n'

/**
 * Adds navigation guards
 * @param {{router: import('vue-router').Router}} param Params.
 */
export const useNavigationGuards = ({ router }) => {
  const { t } = i18n.global
  const notif = useNotyf()
  const applicationStore = useApplicationStore()

  const configurationStore = useConfigurationStore()
  const { logByRouteName } = useAccessLogService()

  /**
   * Alerts access admin access guard
   * @param {() => boolean} permissionCallback Validates if can navigate to next route
   * @param {string} errorMessage Message to dispalay if validation fails
   * @returns {Promise<{name: string} | boolean>} Route object.
   */
  const permissionAccessGuard = async (permissionCallback, errorMessage) => {
    await applicationStore.applicationInitializedWaitHandle
    if (permissionCallback()) return true

    notif.error(errorMessage)
    return { name: RouteItems.Home.name }
  }

  /**
   * Permission access guard
   * @param {import('vue-router').RouteLocationNormalized} to From route.
   * @returns {Promise<import('vue-router').RouteLocationRaw | boolean>} Route location.
   */
  const navigationGuard = async (to) => {
    const routeName = to?.name

    await setLocaleMessages()

    if (!routeName) return { name: RouteNames.TasksInbox }

    switch (routeName) {
      case RouteItems.Search.name:
        return await permissionAccessGuard(
          canAccessClaimSearch,
          t('navigation.claimSearchAccessDenied')
        )
      case RouteItems.ClaimReport.name:
        return await permissionAccessGuard(
          canAccessClaimReport,
          t('navigation.claimReportAccessDenied')
        )
      case RouteItems.TaskReport.name:
        return await permissionAccessGuard(
          canAccessTaskReport,
          t('navigation.taskReportAccessDenied')
        )
      case RouteItems.Payment.name:
        return await permissionAccessGuard(
          canAccessPaymentReport,
          t('navigation.paymentReportAccessDenied')
        )
      case RouteItems.EmailTaskReport.name:
        return await permissionAccessGuard(
          canAssessEmailTaskDetailsReport,
          t('navigation.emailTaskReportAccessDenied')
        )
      case RouteItems.FinancialReports.name:
        return await permissionAccessGuard(
          canAccessFinancialReports,
          t('navigation.financialReportAccessDenied')
        )
      case RouteItems.Audit.name:
        return await permissionAccessGuard(
          canAccessAuditReports,
          t('navigation.auditReportAccessDenied')
        )
      case RouteItems.UserAdministration.name:
        return await permissionAccessGuard(
          canAccessUsersAdmin,
          t('navigation.userAdminAccesDenied')
        )
      case RouteItems.UserRolesAdministrator.name:
        return await permissionAccessGuard(
          canAccessRolesAdmin,
          t('navigation.rolesAdminAccessDenied')
        )
      case RouteItems.OrgStructure.name:
        return await permissionAccessGuard(
          canAccessOrgAdmin,
          t('navigation.orgStructureAdminAccessDenied')
        )
      case RouteItems.ApprovalLimitsAdministration.name:
        return await permissionAccessGuard(
          canAccessApprovalLimitsAdmin,
          t('navigation.approvalLimitAccessDenied')
        )
      case RouteItems.WorkflowAdministration.name:
        return await permissionAccessGuard(
          canAccessWorkflowAdmin,
          t('navigation.workflowAccessDenied')
        )
      case RouteItems.TriageAdministration.name:
        return await permissionAccessGuard(
          canAccessTriageAdmin,
          t('navigation.triageAccessDenied')
        )
      case RouteItems.EmailTemplatesAdministration.name:
        return await permissionAccessGuard(
          canAccessEmailTemplatesAdmin,
          t('navigation.emailTemplatesAccessDenied')
        )
      case RouteItems.AlertAdministration.name:
        return await permissionAccessGuard(
          canAccessAlertsAdmin,
          t('navigation.alertsAccessDenied')
        )
      case RouteItems.FeatureConfiguration.name:
        return await permissionAccessGuard(
          canAccessConfigurationAdmin,
          t('navigation.configurationAccessDenied')
        )
      case RouteItems.SystemConfiguration.name:
        return await permissionAccessGuard(
          canAccessConfigurationAdmin,
          t('navigation.configurationAccessDenied')
        )
      case RouteItems.RagStatus.name:
        return await permissionAccessGuard(
          canAccessTaskRagAdmin,
          t('navigation.taskRAGAdminAccessDenied')
        )
      case RouteItems.SubclaimsAdministration.name:
        return await permissionAccessGuard(
          canAccessSubclaimAdmin,
          t('navigation.subclaimsItemsAdminAccessDenied')
        )
      case RouteItems.OptionsConfiguration.name:
        return await permissionAccessGuard(
          canAccessOptionsAdmin,
          t('navigation.optionsConfigAdminAccessDenied')
        )
      case RouteItems.ClassOfBusinessAdministration.name:
        return await permissionAccessGuard(
          canAccessClassOfBusinessAdmin,
          t('navigation.cobAdminAccessDenied')
        )
      case RouteItems.ClassOfBusinessGroupAdministration.name:
        return await permissionAccessGuard(
          canAccessClassOfBusinessGroupAdmin,
          t('navigation.cobGroupAdminAccessDenied')
        )
      case RouteItems.HolidayAdministration.name:
        return await permissionAccessGuard(
          canAccessHolidayAdmin,
          t('navigation.holidayAdminAccessDenied')
        )
      case RouteItems.PaymentsAdministration.name:
        return await permissionAccessGuard(
          canAccessPaymentsAdmin,
          t('navigation.paymentAdminAccessDenied')
        )
      case RouteItems.ParticipantsExpertsAdministration.name:
        return await permissionAccessGuard(
          canAccessParticipantsAdmin,
          t('navigation.participantsAdminAccessDenied')
        )
      case RouteItems.EventsManagementAdministration.name:
        return await permissionAccessGuard(
          canAccessEventManagementAdmin,
          t('navigation.eventManagementAdminAccessDenied')
        )
      case RouteItems.ClaimsMasterDataAdministration.name:
        return await permissionAccessGuard(
          canAccessMasterDataAdmin,
          t('navigation.masterDataAdminiAccessDenied')
        )
      default:
        return true
    }
  }

  // Permission access check
  router.beforeEach(navigationGuard)

  // Logging access
  router.afterEach((to) => {
    const routeName = to?.name?.toString()

    if (!routeName) return

    logByRouteName({ routeName })
    if (!to.meta.ignoreForSetSelectedMenu) configurationStore.setSelectedMenu(routeName)
  })
}
