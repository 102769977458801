// Admin access permissions
export const AllAdminFullAccess = 'All_Admin_Full_Access'
export const AllAdminReadAccess = 'All_Admin_Read_Access'
export const MasterDataAdminFullAccess = 'Master_Data_Admin_Full_Access'
export const MasterDataAdminReadAccess = 'Master_Data_Admin_Read_Access'
export const OptionsAdminReadAccess = 'Options_Admin_Read_Access'
export const OptionsAdminFullAccess = 'Options_Admin_Full_Access'
export const TaskRagAdminReadAccess = 'RAG_Admin_Read_Access'
export const TaskRagAdminFullAccess = 'RAG_Admin_Full_Access'
export const SubclaimAdminReadAccess = 'Subclaim_Admin_Read_Access'
export const SubclaimAdminFullAccess = 'Subclaim_Admin_Full_Access'
export const ParticipantsAdminReadAccess = 'Participants_Admin_Read_Access'
export const ParticipantsAdminFullAccess = 'Participants_Admin_Full_Access'
export const PaymentsAdminReadAccess = 'Payment_Admin_Read_Access'
export const PaymentsAdminFullAccess = 'Payment_Admin_Full_Access'
export const HolidayAdminReadAccess = 'Holiday_Admin_Read_Access'
export const HolidayAdminFullAccess = 'Holiday_Admin_Full_Access'
export const ClassOfBusinessAdminReadAccess = 'Class_of_Business_Admin_Read_Access'
export const ClassOfBusinessAdminFullAccess = 'Class_of_Business_Admin_Full_Access'
export const ClassOfBusinessGroupAdminReadAccess =
  'Class_of_business_group_Admin_Read_Access'
export const ClassOfBusinessGroupAdminFullAccess =
  'Class_of_business_group_Admin_Full_Access'
export const AlertsAdminReadAccess = 'Alerts_Admin_Read_Access'
export const AlertsAdminFullAccess = 'Alerts_Admin_Full_Access'
export const PaymentEmailAdminFullAccess = 'Payment_Email_Admin_Full_Access'
export const ConfigAdminReadAccess = 'Config_Read_Access'
export const ConfigAdminFullAccess = 'Config_Full_Access'
export const ApprovalLimitsAdminReadAccess = 'Approval_Limits_Admin_Read_Access'
export const ApprovalLimitsAdminFullAccess = 'Approval_Limits_Admin_Full_Access'
export const UsersAdminFullAccess = 'User_Admin_Full_Access'
export const UsersAdminReadAccess = 'User_Admin_Read_Access'
export const RolesAdminReadAccess = 'Roles_Admin_Read_Access'
export const RolesAdminFullAccess = 'Roles_Admin_Full_Access'
export const OrgAdminReadAccess = 'Org_Admin_Read_Access'
export const OrgAdminFullAccess = 'Org_Admin_Full_Access'
export const WorkflowAdminReadAccess = 'Workflow_Admin_Read_Access'
export const WorkflowAdminFullAccess = 'Workflow_Admin_Full_Access'
export const TriageAdminFullAccess = 'Triage_Admin_Full_Access'
export const TriageAdminReadAccess = 'Triage_Admin_Read_Access'
export const EmailTemplatesAdminFullAccess = 'Email_Templates_Admin_Full_Access'
export const EmailTemplatesAdminReadAccess = 'Email_Templates_Admin_Read_Access'
export const EventManagementAdminReadAccess = 'Event_Management_Admin_Read_Access'
export const EventManagementAdminFullAccess = 'Event_Management_Admin_Full_Access'

// Report access permissions
export const AllReportAccess = 'All_Reports_Access'
export const ClaimSearchAccess = 'Claim_Search_Access'
export const ClaimsReportAccess = 'Claims_Report_Access'
export const FinancialReportAccess = 'Financial_Report_Access'
export const TaskReportAccess = 'Task_Report_Access'
export const PaymentReportAccess = 'Payment_Report_Access'
export const EmailTaskDetailsReportAccess = 'Email_Task_Details_Report_Access'
export const AccessAuditReportAccess = 'Access_Audit_Report_Access'
export const AdminAuditReportAccess = 'Admin_Audit_Report_Access'
export const ClaimAuditReportAccess = 'Claim_Audit_Report_Access'
export const EmailAuditReportAccess = 'Email_Audit_Report_Access'

// Dashboard access permissions
export const DashboardAllToggleAccess = 'Dashboard_All_Toggle_Access'
export const DashboardCustomToggleAccess = 'Dashboard_Custom_Toggle_Access'

// Claim access permissions
export const allClaimsFullAccess = 'All_Claims_Full_Access'
export const allClaimsReadAccess = 'All_Claims_Read_Access'
export const claimsFullAccessSufix = '_Claims_Full_Access'
export const claimsReadAccessSufix = '_Claims_Read_Access'

// Email inbox permissions
export const EmailInboxAccess = 'Email_Inbox_Access'
export const ManagerEmailInboxAccess = 'Manager_Email_Inbox_Access'
export const OutstandingEmailInboxAccess = 'Outstanding_Email_Inbox_Access'

// Tasks inbox permissions
export const OutstandingInboxAccess = 'Outstanding_Inbox_Access'
export const ManagerInboxAccess = 'Manager_Inbox_Access'
export const ClaimsInboxAccess = 'Claims_Inbox_Access'
